import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';
import { colors } from 'styles/theme';

import { PostBasic } from 'fragments';

import { Layout, SEO, Box, PostGrid, Text } from 'components';

const FilterList = styled(Text.withComponent('ul'))`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

FilterList.defaultProps = {
  px: [1, 2]
};

const FilterItem = styled(Box.withComponent('li'))`
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: ${colors.blue};
  }
`;

FilterItem.defaultProps = {
  mx: [1, null, 2],
  mb: [1]
};

const ImportantPosts = styled(Box)`
  border-bottom: 1px solid ${colors.gray};
`;

ImportantPosts.defaultProps = {
  mb: [5]
};

const Log = ({ data, location }) => {
  const [filter, setFilter] = useState(null);
  const page = data.prismic.allLog_pages.edges.slice(0, 1).pop().node;
  const posts = data.prismic.allLog_items.edges;
  const tags = data.prismic.allLog_tags.edges.filter((t, i) => {
    return t.node._meta.uid !== 'important';
  });

  const importantPosts = [];
  const regularPosts = [];

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  useEffect(() => {
    if (location.hash) {
      const h = location.hash.split('/');

      if (h.length > 1) {
        setFilter(h[h.length - 1]);
      }
    }
  }, [location.hash]);

  const filteredPosts = filter
    ? posts.filter((post, i) => {
        let hasTag = false;

        post.node.tags.forEach(t => {
          if (t.tag && t.tag._meta.uid === filter) {
            hasTag = true;
          }
        });

        return hasTag;
      })
    : posts;

  filteredPosts.forEach((post, i) => {
    const noEmptyTags = post.node.tags.filter(t => {
      return t.tag && RichText.asText(t.tag.tag).length;
    });

    const isImportant =
      noEmptyTags.filter(t => {
        return t.tag && t.tag._meta.uid === 'important';
      }).length > 0;

    if (isImportant) {
      importantPosts.push(post);
    } else {
      regularPosts.push(post);
    }
  });

  const onFilterClick = id => {
    setFilter(id);

    if (id) {
      window.history.replaceState(null, null, `#/filter/${id}`);
    } else {
      window.history.replaceState(null, null, '#');
    }
  };

  return (
    <Layout>
      <SEO {...metaData} />

      <Box mt={[6, 7]} mb={[3, 5, 7]}>
        {importantPosts.length ? (
          <ImportantPosts>
            <PostGrid posts={importantPosts} />
          </ImportantPosts>
        ) : null}

        <Box textAlign="center">
          <Text color="lime" uppercase="true" mb={2}>
            Recent
          </Text>

          <FilterList>
            <FilterItem
              onClick={() => {
                onFilterClick(null);
              }}
            >
              All tags
            </FilterItem>
            {tags.map((t, i) => {
              return (
                <FilterItem
                  key={i}
                  onClick={() => {
                    onFilterClick(t.node._meta.uid);
                  }}
                >
                  {RichText.asText(t.node.tag)}
                </FilterItem>
              );
            })}
          </FilterList>
        </Box>

        <PostGrid posts={regularPosts} />
      </Box>
    </Layout>
  );
};

Log.fragments = [PostBasic];

export default Log;

export const query = graphql`
  {
    prismic {
      allLog_pages {
        edges {
          node {
            meta_title
            meta_description
            meta_image
          }
        }
      }
      allLog_items(sortBy: date_DESC) {
        edges {
          node {
            ...PostBasic
          }
        }
      }
      allLog_tags {
        edges {
          node {
            tag
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;

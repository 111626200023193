import { graphql } from 'gatsby';

export const PostBasic = graphql`
  fragment PostBasic on PRISMIC_Log_item {
    _meta {
      uid
      type
      firstPublicationDate
      lastPublicationDate
    }
    title
    subtitle
    date
    meta_title
    meta_description
    meta_image
    image
    imageSharp {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          aspectRatio
        }
      }
    }
    tags {
      tag {
        ... on PRISMIC_Log_tag {
          tag
          _meta {
            uid
          }
        }
      }
    }
  }
`;

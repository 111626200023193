import { graphql } from 'gatsby';

export const ProjectBasic = graphql`
  fragment ProjectBasic on PRISMIC_Project {
    _meta {
      uid
      type
    }
    meta_title
    meta_description
    meta_image
    title
    subtitle
    home_page_image
    home_page_imageSharp {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    work_page_image
    work_page_imageSharp {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home_video {
      ... on PRISMIC__FileLink {
        url
        size
      }
    }
    work_video {
      ... on PRISMIC__FileLink {
        url
        size
      }
    }
    client {
      ... on PRISMIC_Client {
        name
      }
    }
    type {
      project_type {
        ... on PRISMIC_Project_type {
          label
        }
      }
    }
    tags {
      tag {
        ... on PRISMIC_Tag {
          tag
        }
      }
    }
    interest_tags {
      interest {
        ... on PRISMIC_Interest_tag {
          label
        }
      }
    }
  }
`;

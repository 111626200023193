import { graphql } from 'gatsby';

export const ProjectSlices = graphql`
  fragment ProjectSlices on PRISMIC_Project {
    body1 {
      ... on PRISMIC_ProjectBody1Intro {
        type
        primary {
          intro_background_color
          intro_background_second_color
          intro_image
          intro_imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          intro_section_layout
        }
      }
      ... on PRISMIC_ProjectBody1Intro_as_video {
        type
        primary {
          intro_background_color
          intro_background_second_color
          intro_section_layout
          intro_video
        }
      }
      ... on PRISMIC_ProjectBody1Paragraph {
        type
        primary {
          paragraph
          paragraph_align
          paragraph_background_color
          paragraph_height
          paragraph_layout
        }
      }
      ... on PRISMIC_ProjectBody1Image {
        type
        primary {
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_layout
          image_description
        }
      }
      ... on PRISMIC_ProjectBody1Video {
        type
        primary {
          background
          cover_image
          cover_imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video
          video_description
          video_layout
        }
      }
      ... on PRISMIC_ProjectBody1Image___paragraph {
        type
        primary {
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media_background_color
          media_position
          paragraph
          paragraph_background_color
          paragraph_text_color
        }
      }
    }
  }
`;

import { graphql } from 'gatsby';

export const PostSlices = graphql`
  fragment PostSlices on PRISMIC_Log_item {
    body1 {
      ... on PRISMIC_Log_itemBody1Intro {
        type
        primary {
          intro_background_color
          intro_background_second_color
          intro_image
          intro_imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          intro_section_layout
        }
      }
      ... on PRISMIC_Log_itemBody1Intro_as_video {
        type
        primary {
          intro_background_color
          intro_background_second_color
          intro_section_layout
          intro_video
        }
      }
      ... on PRISMIC_Log_itemBody1Paragraph {
        type
        primary {
          paragraph
          paragraph_align
          paragraph_background_color
          paragraph_height
          paragraph_layout
        }
      }
      ... on PRISMIC_Log_itemBody1Image {
        type
        primary {
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_layout
          image_description
        }
      }
      ... on PRISMIC_Log_itemBody1Video {
        type
        primary {
          background
          cover_image
          cover_imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video
          video_description
          video_layout
        }
      }
      ... on PRISMIC_Log_itemBody1Image___paragraph {
        type
        primary {
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media_background_color
          media_layout
          media_position
          paragraph
          paragraph_background_color
          paragraph_text_color
          video
        }
      }
    }
  }
`;
